import { supabase } from '$lib/supabase';
import { mount } from 'svelte';
import './app.css';
import App from './views/App.svelte';

async function initializeApp() {
    // Check initial auth state
    const {
        data: { user },
    } = await supabase.auth.getUser();

    if (!user) {
        window.location.href = '/login.html';
        return;
    }
    return mount(App, {
        target: document.getElementById('app')!,
        props: { user },
    });
}

let app = await initializeApp();

export default app;
