<script lang="ts">
    import type { Instance } from '@mateothegreat/svelte5-router';
    import { route, Router } from '@mateothegreat/svelte5-router';
    import { HomeIcon, Newspaper, User } from 'lucide-svelte';

    type NavItemType = {
        href: string;
        icon: typeof HomeIcon;
        label: string;
    };

    const routes = [
        {
            path: '/curations',
            component: async () => import('./pages/curations/Page.svelte'),
        },
        {
            path: '/authors',
            component: async () => import('./pages/authors/Page.svelte'),
        },
        {
            path: '/',
            component: async () => import('./pages/dashboard/Page.svelte'),
        },
    ];

    let instance = $state<Instance>();
</script>

{#snippet NavItem({ href, icon: Icon, label }: NavItemType)}
    {@const isActive = instance?.current?.path === href}
    <a
        {href}
        use:route
        class="group {isActive
            ? 'bg-white/10 text-white'
            : 'text-gray-300'} flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm font-medium transition-all duration-200 hover:bg-white/10 hover:text-white">
        <Icon size={18} class="opacity-75" />
        {label}
    </a>
{/snippet}

<div class="flex overflow-hidden max-h-full">
    <!-- Sidebar -->
    <div
        class=" inset-y-0 left-0 z-20 w-44 h-full shadow-xl transition-transform duration-300 ease-out lg:static">
        <nav class="mt-6 px-3 flex flex-col gap-1">
            {@render NavItem({
                href: '/',
                icon: HomeIcon,
                label: 'Dashboard',
            })}
            {@render NavItem({
                href: '/curations',
                icon: Newspaper,
                label: 'Curations',
            })}
            {@render NavItem({
                href: '/authors',
                icon: User,
                label: 'Authors',
            })}
        </nav>
    </div>

    <div class="flex flex-col w-full max-h-full">
        <!-- Main content -->
        <main class="h-full flex-1 overflow-y-auto p-6">
            <Router {routes} basePath="/" bind:instance />
        </main>
    </div>
</div>
