<script lang="ts">
    import { Instance, route, Router } from "@mateothegreat/svelte5-router";
    import { HomeIcon, ListTree, Newspaper, Settings } from "lucide-svelte";

    type NavItemType = {
        href: string;
        icon: typeof HomeIcon;
        label: string;
    };

    let instance = $state<Instance>();

    const routes = [
        {
            path: "/fonts",
            component: async () => import("./pages/fonts/Page.svelte"),
        },
        {
            path: "/submissions",
            component: async () => import("./pages/submissions/Page.svelte"),
        },
        {
            path: "/settings",
            component: async () => import("./pages/settings/Page.svelte"),
        },
        {
            path: "/",
            component: async () => import("./pages/dashboard/Page.svelte"),
        },
    ];

    const navItems = [
        {
            href: "/",
            icon: HomeIcon,
            label: "Dashboard",
        },
        {
            href: "/fonts",
            icon: Newspaper,
            label: "Fonts",
        },
        {
            href: "/submissions",
            icon: ListTree,
            label: "Submissions",
        },
    ];
</script>

{#snippet NavItem({ href, icon: Icon, label }: NavItemType)}
    {@const isActive = instance?.current?.path === href}
    <a
        {href}
        use:route
        class="group {isActive
            ? 'bg-white/10 text-white'
            : 'text-gray-300'} flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm font-medium transition-all duration-200 hover:bg-white/10 hover:text-white"
    >
        <Icon size={18} class="opacity-75" />
        {label}
    </a>
{/snippet}

<div class="flex overflow-hidden max-h-full">
    <!-- Sidebar -->
    <div
        class=" inset-y-0 left-0 z-20 w-44 h-full shadow-xl transition-transform duration-300 ease-out lg:static"
    >
        <nav class="mt-6 px-3 flex flex-col gap-1">
            {#each navItems as item}
                {@render NavItem(item)}
            {/each}

            <hr class="my-4 border-white/10" />
            {@render NavItem({
                href: "/settings",
                icon: Settings,
                label: "Settings",
            })}
        </nav>
    </div>

    <div class="flex flex-col w-full max-h-full">
        <!-- Main content -->
        <main class="h-full flex-1 overflow-y-auto p-6">
            <Router {routes} basePath="/" bind:instance />
        </main>
    </div>
</div>
