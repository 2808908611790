<script lang="ts">
    import Button from '$lib/components/ui/button/button.svelte';
    import { supabase } from '$lib/supabase';
    import BestDesignsOnX from '@/apps/bestofx/BestDesignsOnX.svelte';
    import CurationsSupply from '@/apps/curations-supply/CurationsSupply.svelte';
    import Fotd from '@/apps/fotd/Fotd.svelte';
    import type { User } from '@supabase/supabase-js';
    import { Toaster } from 'svelte-sonner';

    const {
        user,
    }: {
        user: User | null;
    } = $props();

    let app: string = $state(localStorage.getItem('app') || 'fotd');
    const apps = {
        fotd: {
            name: 'FOTD',
            icon: '/fotd/icon.svg',
            component: Fotd,
        },
        curationsSupply: {
            name: 'Curations Supply',
            icon: '/curations-supply/icon.svg',
            component: CurationsSupply,
        },
        bestofX: {
            name: 'Best Designs on X',
            icon: '/bestofx/logo.jpg',
            component: BestDesignsOnX,
        },
    };

    $effect(() => {
        localStorage.setItem('app', app);
    });

    const selectedApp = $derived(apps[app as keyof typeof apps]);
    const Application: any = $derived(selectedApp.component);

    async function handleSignOut() {
        const { error } = await supabase.auth.signOut();
        if (!error) {
            window.location.href = '/login';
        }
    }
</script>

{#snippet Item(key: string, name: string, icon: string)}
    <button
        class="flex items-center gap-2 rounded-md p-2 hover:bg-gray-100/10 {app ===
        key
            ? 'bg-gray-100/10'
            : ''}"
        onclick={() => {
            window.history.pushState({}, '', '/');
            app = key;
        }}>
        <div class="flex items-center gap-2">
            <img src={icon} alt={name} class="h-6 w-6" />
        </div>
    </button>
{/snippet}

<Toaster />
<div class="flex flex-col h-full">
    <header class="z-10 border-b backdrop-blur-sm">
        <div class="flex h-12 items-center justify-between px-6">
            <div class="flex items-center gap-3">
                <img
                    src="https://panda-network.b-cdn.net/network-bar/icons/panda.png"
                    alt="Panda"
                    class="h-6 w-6" />
                <h2 class="text-lg font-medium">Panda Panel</h2>
                -

                <img
                    src={selectedApp.icon}
                    alt={selectedApp.name}
                    class="h-4 w-4" />
                <span class="text-sm font-medium">
                    {selectedApp.name}
                </span>
            </div>
            <div class="flex items-center gap-4">
                {#if user}
                    <span class="hidden text-sm md:block">{user.email}</span>
                    <Button
                        onclick={handleSignOut}
                        variant="outline"
                        size="sm"
                        class="font-medium">
                        Sign Out
                    </Button>
                {/if}
            </div>
        </div>
    </header>
    <div class="flex flex-1 overflow-hidden h-full">
        <div class="flex flex-col gap-4 p-2">
            {#each Object.entries(apps) as [key, app]}
                {@render Item(key, app.name, app.icon)}
            {/each}
        </div>
        <div class="flex-1">
            <Application {user} />
        </div>
    </div>
</div>
