import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { supabase } from './supabase';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export async function convertToWebP(file: File): Promise<File | null> {
    try {
        console.log('Converting to webp...');

        const image = await createImageBitmap(file);
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(image, 0, 0);

        const webpBlob = await new Promise<Blob>((resolve) => {
            canvas.toBlob((blob) => resolve(blob!), 'image/webp', 0.8);
        });

        const convertedFile = new File(
            [webpBlob],
            file.name.replace(/\.[^/.]+$/, '.webp'),
            {
                type: 'image/webp',
            },
        );
        console.log('Convert sucessful', convertedFile);

        return convertedFile;
    } catch (error) {
        console.error('Error converting to webp:', error);
        return null;
    }
}

export function createSlug(parts: string[]) {
    let slug = "";
    parts.forEach((part) => {
        slug += part.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
    });
    return slug;
}

export async function getWebsiteDetails(url: string) {
    const formData = new FormData();
    formData.append('url', url);

    const { data, error } = await supabase.functions.invoke('getog', {
        headers: {
            Accept: '*/*',
        },
        body: formData,
    });

    if (error) {
        console.error('Error getting website details', error);
        return {
            title: '',
            description: '',
            websiteImage: '',
        };
    }

    return data;
}


